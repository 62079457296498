html {
  scroll-snap-type: y mandatory;
  background: #f3f5f9;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  height: 100vh;
  border-bottom: 1px solid white;
  scroll-snap-align: start;

  &:not(:first-of-type):before {
    position: absolute;
    top: 60px;
    display: inline-block;
    content: '';
    -ms-transform: rotate(-135deg); /* IE 9 */
    -webkit-transform: rotate(-135deg); /* Chrome, Safari, Opera */
    transform: rotate(-135deg);
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    width: 16px;
    height: 16px;
  }

  &:not(:first-of-type):not(:last-of-type):after {
    position: absolute;
    bottom: 60px;
    display: inline-block;
    content: '';
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    width: 16px;
    height: 16px;
  }
}

.section {
  .section__header {
    position: absolute;
    top: 10px;
  }

  .section__footer {
    position: absolute;
    bottom: 50px;
  }

  .section__body {
    display: flex;
    justify-content: center;
    align-items: center;

    .personal {
      display: flex;
      justify-content: center;
      align-items: center;

      .picture {
        img {
          border-radius: 50%;
          width: 180px;
        }
      }

      .info {
        .name {
          font-size: 2rem;
          font-family: 'Playfair Display';
        }

        .title {
          span {
            color: #2c98f0;
          }
        }
      }
    }

    .links {
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          height: 2rem;
          width: 2rem;
          margin-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .section {
    flex-direction: column;

    .section__body {
      flex-direction: column;
    }

    .personal {
      flex-direction: column;
      text-align: center;
    }

    .links {
      p {
        display: inline-block;
        margin: 20px;

        span {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .personal {
    .picture {
      margin-right: 3rem;
    }

    .info {
      margin-right: 2rem;
    }
  }
}
