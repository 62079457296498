html, body {
  font-family: 'Quicksand', sans-serif;
}

h1 {
  font-family: 'Raleway', sans-serif;
}

p {
  font-family: 'Poppins', sans-serif;
}
